import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const MainContainer = styled.div`
  border: solid;
  border-color: #a1a1a1;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 2410px) {
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 827px) {
    border: none;
    width: fit-content;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.h2`
  height: 38px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #202323;
  flex: none;
  flex-grow: 24px;
  margin-top: 24px;
  margin-bottom: 10px;

  @media (max-width: 827px) {
    font-weight: 400;
    font-size: 20px;
  }
`;

const Amount = styled.h2`
  height: 60px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  text-align: center;
  letter-spacing: -1px;
  color: #ff6d00;
  flex: none;
  flex-grow: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 862px) {
    font-weight: 400;
    font-size: 28px;
  }
`;

const BreakdownOn = styled.h2`
  height: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #525c66;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;

  @media (max-width: 827px) {
    font-weight: 400;
    font-size: 18px;
    margin-top: 0px;
  }

  @media (max-width: 528px) {
    font-weight: 200;
    font-size: 16px;
  }
`;

const BreakdownOff = styled.span`
  height: 30px;
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #525c66;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
`;

const SalaryPerRoleContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 545px) {
    flex-direction: column;
  }
`;

const Role = styled.span`
  width: 300px;
  height: 24px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #525c66;
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: 1200px) {
    width: 200px;
  }

  @media (max-width: 827px) {
    width: fit-content;
    padding-right: 20px;
  }
`;

const Salary = styled.span`
  height: 24px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #525c66;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const CostContainer = styled.div`
  @media (max-width: 1212px) {
    margin: 0px 100px;
  }

  @media (max-width: 827px) {
    margin: 0px 20px;
  }

  @media (max-width: 460px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const ArrowContainer = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
`;
const NoSalaryContainer = styled.div`
  width: 85px;
  display: flex;
  justify-content: center;
  @media (max-width: 740px) {
    width: 10px;
    align-self: flex-start;
    margin-left: 12px;
  }
`;

const InfoContainer = styled.div`
  padding-left: 10px;
`;

const AnnualCost = ({ total, salaryPerRole, anotherRoles }) => {
  const [costBreakdown, setCostBreakdown] = useState(false);

  return (
    <MainContainer>
      <Title> Annual Cost</Title>
      <Amount>{total?.toLocaleString('en-US')}</Amount>

      {!costBreakdown && (
        <BreakdownOn onClick={() => setCostBreakdown(true)}>
          Cost Breakdown <FontAwesomeIcon icon={faArrowDown} />
        </BreakdownOn>
      )}
      {costBreakdown && (
        <CostContainer>
          {salaryPerRole
            ?.filter(role => !role.role.includes('Another Role'))
            .filter(role => {
              if (anotherRoles) {
                return !Object.entries(anotherRoles).some(anotherRole => anotherRole[1].name === role.role);
              }
              return true;
            })
            .flatMap(({ role, amount, individualSalary }) =>
              Array.from({ length: amount }, () => ({ role, amount: 1, individualSalary })),
            )
            .map(role => (
              <SalaryPerRoleContainer>
                <Role>{role.role}</Role>
                <Salary>{role?.individualSalary?.toLocaleString('en-US')}</Salary>
              </SalaryPerRoleContainer>
            ))}
          {anotherRoles &&
            Object.entries(anotherRoles)?.map(role => (
              <SalaryPerRoleContainer>
                <Role>{role[1].name}</Role>
                <NoSalaryContainer>
                  <Salary>-</Salary>
                  <InfoContainer>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ color: '#00a5c8' }}
                      data-tip='No salary range for this custom role. We’ll contact you to book a meeting and get more information.'
                    />
                    <ReactTooltip
                      aria-haspopup='true'
                      border
                      borderColor='#d2d2d2'
                      type='light'
                      place='right'
                      className='bubbles-tooltip'
                    />
                  </InfoContainer>
                </NoSalaryContainer>
              </SalaryPerRoleContainer>
            ))}
          <BreakdownOff onClick={() => setCostBreakdown(false)}>
            Less
            <ArrowContainer>
              <FontAwesomeIcon icon={faArrowUp}> </FontAwesomeIcon>
            </ArrowContainer>
          </BreakdownOff>
        </CostContainer>
      )}
    </MainContainer>
  );
};

export default AnnualCost;
