import React from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroContainer = styled.div`
  background: var(--gradient-btn-1-hover, linear-gradient(90deg, #01c8ef 0%, #00a5c8 100%));
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  padding: 32px 0px;

  @media (max-width: 887px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 80px;
  @media (max-width: 887px) {
    width: 90%;
    padding-left: 0px;
  }
`;

const Text = styled.p`
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 0px;
`;

const DownloadButtonContainer = styled.div`
  margin-top: 28px;

  @media (max-width: 887px) {
    width: 100%;
  }
`;

const DownloadButton = styled.button`
  border-radius: 100px;
  border: 2px solid var(--shade-white-0, #fff);
  background-color: transparent;
  display: flex;
  height: 56px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 887px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 38%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1300px) {
    width: 428px;
  }

  @media (max-width: 887px) {
    width: 90%;
    justify-content: center;
    margin-top: 16px;
  }
`;

const FooterBanner = () => {
  const { footerBanner } = useStaticQuery(graphql`
    {
      footerBanner: file(relativePath: { eq: "images/salary_survey_mockupbook.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 400, height: 289, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  return (
    <HeroContainer>
      <ImageContainer>
        <GatsbyImage image={footerBanner?.childImageSharp.gatsbyImageData} alt='Footer Banner Image' />
      </ImageContainer>
      <TextContainer>
        <Text>
          Our calculator is built with accurate data collected from our <strong>Compensation Trends Report</strong> each
          quarter, ensuring that you get a precise estimate of your team’s cost.
        </Text>
        <Text>
          <strong>Access our quarterly Tech Salaries and Benefits Trends for more valuable insights</strong>
        </Text>
        <DownloadButtonContainer>
          <GatsbyLink
            to='https://talent.nextonlabs.com/tech-salaries-and-benefits-trends-report/'
            target='_blank'
            rel='noopener'>
            <DownloadButton>Download</DownloadButton>
          </GatsbyLink>
        </DownloadButtonContainer>
      </TextContainer>
    </HeroContainer>
  );
};

export default FooterBanner;
