import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SelectWrapper = styled.div`
  width: 410px;
  height: 60px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  background: #ffffff;
  position: relative;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;

  @media (max-width: 410px) {
    width: 290px;
  }
`;

const SelectButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  height: 100%;
  cursor: pointer;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #525c66;
`;

const SelectList = styled.ul`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  background: #ffffff;
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
`;

const SelectListItem = styled.li`
  padding: 10px 14px;
  cursor: pointer;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #525c66;
  &:hover {
    background: #f5f5f5;
  }
  ${props =>
    props.selected &&
    `
    background: #f5f5f5;
  `}
`;

const RoleSelect = ({ options, onSelect, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef();

  useEffect(() => {
    if (!options.includes('Another Role')) {
      options.push('Another Role');
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOptionSelect = option => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <SelectWrapper ref={wrapperRef}>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        {selectedOption || 'Additional Role'} <FontAwesomeIcon icon={faChevronDown} />
      </SelectButton>
      {isOpen && (
        <SelectList>
          {options?.map(option => (
            <SelectListItem
              key={option}
              onClick={() => handleOptionSelect(option)}
              selected={selectedOption === option}>
              {option}
            </SelectListItem>
          ))}
        </SelectList>
      )}
    </SelectWrapper>
  );
};

export default RoleSelect;
