import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Link, Button } from 'rebass';
import RoleInput from '../role-input';
import RoleSelect from '../role-select';
import ShowResults from '../show-results';
import AnnualCost from '../show-results/annual-cost';
import Spinner from '../../spinner/Spinner';
import freeEmails from './freeEmails';

const CalculatorTitle = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #202323;
  margin-bottom: 20px; // Adjust as needed for spacing below title
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const EditTeamText = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 38px;
  color: #00a5c8;
  cursor: pointer;
`;

const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  width: ${props => (props.loadingRoles ? '90%' : 'fit-content')};
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.160047);
  border-radius: 24px;
  transform: translateY(5%);

  @media (max-width: 1366px) {
    padding: 24px 16px;
    transform: none;
  }

  @media (max-width: 1270px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 460px) {
    padding: 18px 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ResultsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.160047);
  border-radius: 24px;
`;

const RoleInputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  @media (max-width: 1366px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 13px;
  width: fit-content;
  height: fit-content;
  background: ${props => (props.email !== '' && Object.keys(props.counts).length > 0 ? '#FF6D00' : '#FECAA4')};
  border-radius: 100px;
  border: none;
  outline: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
`;

const InputEmail = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 16px;
  width: 412px;
  height: 62px;
  background: #ffffff;
  border: 1px solid;
  border-color: ${props => (props.value ? '#00A5C8' : '#D0D0D0')} !important;
  color: ${props => (props.value ? '#00A5C8' : 'black')};
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  @media (max-width: 410px) {
    width: 100%;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-top: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AnnualCostContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  padding: 20px 50px 50px 250px;

  @media (max-width: 1500px) {
    padding: 20px 50px 50px 200px;
  }

  @media (max-width: 1000px) {
    padding: 20px 50px 50px 100px;
  }

  @media (max-width: 629px) {
    padding: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SpinnerContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const Calculator = ({ initialRoles, initialAvailableOptions, loadingRoles }) => {
  const [counts, setCounts] = useState(() => {
    const initCounts = {};
    initialRoles.forEach(role => {
      initCounts[role] = 1;
    });
    return initCounts;
  });

  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [freeEmail, setFreeEmail] = useState(false);
  const [roles, setRoles] = useState(initialRoles);
  const [anotherRoles, setAnotherRoles] = useState();
  const [availableOptions, setAvailableOptions] = useState(initialAvailableOptions);
  const [goToNextStep, setGoToNextStep] = useState(false);
  const [loadingCost, setLoadingCost] = useState(false);
  const [annualCost, setAnnualCost] = useState();

  const roleMapping = () => {
    const arr = [];
    Object.keys(counts).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(counts, key)) {
        const value = counts[key];
        if (!key.includes('Another Role')) {
          arr.push({ role: key, amount: value, email });
        }
      }
    });
    if (anotherRoles) {
      Object.entries(anotherRoles).forEach(key => {
        if (key[1].name && key[1].count) {
          arr.push({ role: key[1].name, amount: key[1].count, email });
        }
      });
    }

    return arr;
  };

  const rolesMapped = roleMapping();

  const getAnnualCost = async () => {
    try {
      const response = await fetch(`${process.env.GATSBY_COMPANION_API}/team-cost-calculator/calculate`, {
        method: 'POST',
        body: JSON.stringify(rolesMapped),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      });
      const resultData = await response?.json();
      setAnnualCost(resultData);
      setLoadingCost(false);
    } catch (error) {
      console.log(error);
      setLoadingCost(false);
    }
  };

  useEffect(() => {
    setLoadingCost(true);
    roleMapping();
    getAnnualCost();
  }, [goToNextStep]);

  useEffect(() => {
    setRoles(initialRoles);
    setAvailableOptions(initialAvailableOptions);
  }, [initialRoles, initialAvailableOptions]);

  const validateEmail = value => {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
  };

  const validateFreeEmail = value => {
    const emails = freeEmails();
    const regex = new RegExp(emails.join('|'));
    return regex.test(value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      setInvalidEmail(false);
    }
    if (!validateFreeEmail(event.target.value)) {
      setFreeEmail(false);
    }
  };

  const handleCountChange = (title, count) => {
    if (count === 0) {
      if (roles.length > 0) setRoles(roles?.filter(role => role !== title));
      if (anotherRoles?.length > 0) setAnotherRoles(anotherRoles?.filter(role => role.name !== title));
      if (!title.includes('Another Role')) setAvailableOptions([...availableOptions, title]);
      setCounts(prevCounts => {
        const { [title]: _, ...newCounts } = prevCounts;
        return newCounts;
      });
    } else {
      setCounts({ ...counts, [title]: count });
    }
    if (title.includes('Another Role')) {
      setAnotherRoles({ ...anotherRoles, [title]: { ...anotherRoles[title], count } });
    }
  };
  const handleNameChange = (name, title) => {
    setAnotherRoles({ ...anotherRoles, [title]: { ...anotherRoles[title], name } });
  };

  const handleAdditionalRoleChange = value => {
    if (!roles.includes(value) && value !== 'Another Role') {
      setRoles([...roles, value]);
      setCounts({ ...counts, [value]: 1 });
      setAvailableOptions(availableOptions.filter(option => option !== value));
    }
    if (value === 'Another Role') {
      setRoles([...roles, value + roles.length]);
      setCounts({ ...counts, [value + roles.length]: 1 });
      setAvailableOptions(availableOptions.filter(option => option !== value + roles.length));
      setAnotherRoles({ ...anotherRoles, [value + roles.length]: { count: 1 } });
    }
  };

  return (
    <>
      {!goToNextStep && (
        <CalculatorContainer loadingRoles={loadingRoles}>
          <CalculatorTitle>Select roles you want to calculate</CalculatorTitle>
          {!loadingRoles ? (
            <>
              <RoleInputGrid>
                {roles?.map(role =>
                  !role.includes('Another Role') ? (
                    <RoleInput
                      key={role}
                      title={role}
                      initialCount={counts[role] || 0}
                      handleCountChange={handleCountChange}
                      anotherRole={false}
                    />
                  ) : (
                    <RoleInput
                      key={role}
                      title={role}
                      initialCount={counts[role] || 0}
                      handleCountChange={handleCountChange}
                      handleNameChange={handleNameChange}
                      anotherRole
                    />
                  ),
                )}
                {availableOptions?.length > 0 && (
                  <RoleSelect options={availableOptions} onSelect={handleAdditionalRoleChange} />
                )}
              </RoleInputGrid>
              <SubmitButtonContainer>
                <InputEmail type='email' placeholder='Your Work Email' value={email} onChange={handleEmailChange} />
                <div>
                  <SubmitButton
                    onClick={() => {
                      if (validateEmail(email)) {
                        if (Object.keys(counts).length > 0 && !validateFreeEmail(email)) {
                          setGoToNextStep(true);
                        } else if (validateFreeEmail(email)) {
                          setFreeEmail(true);
                        }
                      } else {
                        setInvalidEmail(true);
                      }
                    }}
                    email={email}
                    counts={counts}>
                    Calculate <FontAwesomeIcon icon={faArrowRight} />
                  </SubmitButton>
                  {invalidEmail && <p style={{ color: 'red', textAlign: 'center' }}>Email is invalid</p>}
                </div>
              </SubmitButtonContainer>
              {freeEmail && (
                <p style={{ color: 'red', textAlign: 'center', maxWidth: '400px' }}>
                  Please enter your business email address. This form does not accept addresses from{' '}
                  {email.split('@')[1]}
                </p>
              )}
            </>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </CalculatorContainer>
      )}
      {goToNextStep && (
        <ResultsContainer>
          <CalculatorTitle>
            Your Team
            <EditTeamText onClick={() => setGoToNextStep(false)}>Edit Team</EditTeamText>
          </CalculatorTitle>
          {!loadingCost ? (
            <>
              <AnnualCostContainer>
                {annualCost?.salaryPerRole
                  ?.filter(role => !role.role.includes('Another Role'))
                  .map(role => (
                    <ShowResults count={role.amount} title={role.role} />
                  ))}
              </AnnualCostContainer>
              <AnnualCost
                total={annualCost?.total}
                salaryPerRole={annualCost?.salaryPerRole}
                anotherRoles={anotherRoles}
              />
            </>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
          <ButtonContainer>
            <Link href='https://talent.nextonlabs.com/tell-us-more-about-your-business'>
              <Button variant='newMainOrange' mt={['12px', '24px']} mb={['12px', '0px']} height={['48px', '55px']}>
                SCHEDULE A CALL NOW
              </Button>
            </Link>
          </ButtonContainer>
        </ResultsContainer>
      )}
    </>
  );
};

export default Calculator;
