import React, { useState } from 'react';
import styled from 'styled-components';
import Counter from '../counter';

const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 410px;
  height: 60px;
  background: #ffffff;
  border: 1px solid ${({ count }) => (count > 0 ? '#00A5C8' : '#D0D0D0')};
  border-radius: 20px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: 410px) {
    min-width: 290px;
    width: fit-content;
  }
`;

const TitleText = styled.span`
  width: fit-content;
  height: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: ${({ count }) => (count > 0 ? '#00A5C8' : '#525C66')};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const TitleTextInput = styled.input`
  width: fit-content;
  max-width: 180px;
  height: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: ${({ count }) => (count > 0 ? '#00A5C8' : '#525C66')};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const RoleInput = ({ title, handleCountChange, initialCount, anotherRole, handleNameChange }) => {
  const [count, setCount] = useState(initialCount);
  const [value, setValue] = useState('');

  const handleSetCount = newCount => {
    setCount(newCount);
    handleCountChange(title, newCount);
  };

  const handleChange = event => {
    setValue(event.target.value);
    handleNameChange(event.target.value, title);
  };

  return (
    <InputContainer count={count}>
      {anotherRole ? (
        <TitleTextInput
          type='text'
          placeholder='Your custom role'
          value={value}
          onChange={handleChange}
          count={count}
        />
      ) : (
        <TitleText count={count}>{title}</TitleText>
      )}
      <Counter count={count} setCount={handleSetCount} />
    </InputContainer>
  );
};

export default RoleInput;
