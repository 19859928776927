import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const AddButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 18px;
  gap: 12px;
  width: fit-content;
  height: 40px;
  border: 2px solid #525c66;
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`;

const CounterButton = styled.div`
  flex: none;
  color: #00a5c8;
  cursor: pointer;
`;

const CounterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 18px;
  gap: 8px;
  width: fit-content;
  height: 40px;
  border: 2px solid #00a5c8;
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
`;

const CountText = styled.span`
  color: #00a5c8;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.5px;
`;

const Counter = ({ count, setCount }) => {
  if (count === 0) {
    return (
      <AddButton type='button' onClick={() => setCount(count + 1)}>
        <FontAwesomeIcon icon={faPlus} />
      </AddButton>
    );
  }

  return (
    <CounterContainer>
      <CounterButton type='button' onClick={() => count > 0 && setCount(count - 1)}>
        <FontAwesomeIcon icon={faMinus} />
      </CounterButton>
      <CountText>{count}</CountText>
      <CounterButton type='button' onClick={() => setCount(count + 1)}>
        <FontAwesomeIcon icon={faPlus} />
      </CounterButton>
    </CounterContainer>
  );
};

export default Counter;
