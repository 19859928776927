import React, { useEffect, useState } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import Calculator from '../components/cost-calculator/calculator';
import FooterBanner from '../components/cost-calculator/FooterBaner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: translateY(10%);

  @media (max-width: 900px) {
    height: 90%;
  }
`;

const Title = styled.h1`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #00a5c8;
  margin: 32px 0px;

  @media (max-width: 911px) {
    margin: 0px 0px 32px 0px;
  }

  @media (max-width: 520px) {
    font-size: 55px;
  }
`;

const Subtitle = styled.h2`
  width: 55%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #3b4b53;
  margin-bottom: 10px;

  @media (max-width: 911px) {
    width: 80%;
    text-align: start;
  }
`;

const StrongSubtitle = styled.h2`
  width: 55%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #3b4b53;
  margin-bottom: 40px;

  @media (max-width: 911px) {
    width: 80%;
    text-align: start;
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: calc(100vh - 100px);
  z-index: -1;
  background: linear-gradient(90deg, rgba(1, 200, 239, 0.1) 0%, rgba(0, 165, 200, 0.1) 100%);
  box-shadow: 0px 41px 60px 11px rgba(161, 161, 161, 0.160047);
`;

const PositionedBox = styled(Box)`
  position: relative;
`;

const defaultRoles = ['Full Stack Engineer', 'Back End Engineer', 'Front End Engineer', 'Tech Lead', 'Product Manager'];

const TeamCostCalculator = () => {
  const [roles, setRoles] = useState();
  const [initialRoles, setInitialRoles] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const getRoles = async () => {
    try {
      const response = await fetch(`${process.env.GATSBY_COMPANION_API}/team-cost-calculator/roles`);
      const resultData = await response?.json();
      setRoles(resultData);
      setLoadingRoles(false);
    } catch (error) {
      console.log(error);
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    if (!roles) {
      setLoadingRoles(true);
      getRoles();
    } else {
      setLoadingRoles(false);
    }
  }, []);

  useEffect(() => {
    if (roles) {
      const newRoleNames = roles?.map(role => role.role);
      const defaultRoleNames = newRoleNames.filter(roleName => defaultRoles.includes(roleName));
      const availableRoleNames = newRoleNames.filter(roleName => !defaultRoles.includes(roleName));

      setInitialRoles(defaultRoleNames);
      setAvailableOptions(availableRoleNames);
    }
  }, [roles]);

  return (
    <>
      <MetaData
        title="Nexton's Team Cost Calculator"
        description="Supercharge growth with accurate expense forecasts and strategic resource allocation using Nexton's intuitive tool."
      />
      <Layout selected='calculate-team-costs'>
        <PositionedBox className='full-width' mb='150px'>
          <Background />
          <Container>
            <Title>Team Cost Calculator</Title>
            <Subtitle>
              Nexton helps you estimate the expenses to make your next new hire with a complete view of what
              compensation costs you can expect to pay by role in just a few simple clicks.
            </Subtitle>
            <StrongSubtitle>
              All compensation estimates correspond to a senior role with 5 to 8 years of experience.
            </StrongSubtitle>
            <Calculator
              initialRoles={initialRoles}
              initialAvailableOptions={availableOptions}
              loadingRoles={loadingRoles}
            />
            <FooterBanner />
          </Container>
        </PositionedBox>
      </Layout>
    </>
  );
};

export default TeamCostCalculator;
