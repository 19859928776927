import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  width: 242px;
  justify-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px;
  gap: 8px;
  height: 30px;
  background: #ffffff;
  border: #00a5c8;
  border-radius: 20px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: 1280px) {
    width: 250px;
    padding: 0px 0px;
  }

  @media (max-width: 968px) {
    width: 250px;
    padding: 0px 0px;
  }
`;

const TitleText = styled.span`
  width: fit-content;
  height: 30px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #00a5c8;
  flex: none;
  flex-grow: 0;
`;

const ShowResults = ({ count, title }) => {
  return (
    <MainContainer>
      <TitleText>{count}</TitleText>
      <TitleText>{title}</TitleText>
    </MainContainer>
  );
};

export default ShowResults;
